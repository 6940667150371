<template>
  <div class="listCode">
    <!-- <Viewheader class="full-width" :isHome="false" /> -->
    <codeNavbar></codeNavbar>
    <div class="listCodeMain">
      <div class="codeNav">
        <leftNav />
      </div>
      <div class="codeList">
        <div class="codeName">code管理 > code创建</div>
        <div class="yPcklQR">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-col :span="11">
              <el-form-item label="业务方：" prop="bizUnit">
                <el-select
                  v-model="ruleForm.bizUnit"
                  placeholder="请选择业务方"
                  style="width: 95%"
                  @change="provinceTypes"
                >
                  <el-option
                    v-for="(item, index) in bizUnitListVal"
                    :key="index"
                    :label="item.bizUnitName"
                    :value="item.bizUnit"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="项目名：" prop="bizName">
                <el-input
                  v-model="ruleForm.bizName"
                  placeholder="如:gs流量提升-标题优化"
                  style="width: 95%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="需求人：" prop="bizDemander">
                <el-input
                  v-model="ruleForm.bizDemander"
                  placeholder="如：duanbobo"
                  style="width: 95%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="callTime">
              <el-form-item label="调用有效时间：" prop="date">
                <el-date-picker
                  v-model="ruleForm.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 83%"
                  value-format="yyyy-MM-dd"
                  @change="timeChange"
                  :disabled="timeChangeClicking"
                  @handleClose="handleClear"
                >
                </el-date-picker>
              </el-form-item>
              <el-checkbox
                v-model="checked"
                class="longTterm"
                :disabled="longTtermClicking"
                @change="handleCheckedCitiesChange"
                >长期</el-checkbox
              >
            </el-col>
            <el-col :span="11" class="callTime">
              <el-form-item label="每日峰值调用量：" prop="dayCallMax">
                <el-input
                  v-model.number="ruleForm.dayCallMax"
                  placeholder="项目max每日最大总调用量"
                  autocomplete="off"
                  style="width: 88%"
                  type="number"
                ></el-input>
              </el-form-item>
              <div class="longTterm">次</div>
            </el-col>
            <el-col :span="11" class="callTime">
              <el-form-item label="max-qps：" prop="qpsMax">
                <el-input
                  v-model="ruleForm.qpsMax"
                  placeholder=""
                  style="width: 95%"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="callTime">
              <el-form-item label="max-调用量：" prop="callMax">
                <el-input
                  v-model="ruleForm.callMax"
                  placeholder="项目max最大总调用量"
                  style="width: 88%"
                  type="number"
                ></el-input>
              </el-form-item>
              <div class="longTterm">万次</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="工程接口：" prop="projectInterface">
                <!-- v-on:input="bizPageOn" -->
                <el-input
                  v-model="ruleForm.projectInterface"
                  placeholder=""
                  style="width: 95%"
                ></el-input>
              </el-form-item>
              <!-- <div class="selectprojectInterface" v-if="projectInterfaceLikeTre">
                            <span v-for="(item,index) in projectInterfaceLikeResult" 
                            :key="index" @click="selectprojectInterfaceValue(item.projectInterface)">{{ item.projectInterface }}</span>
                        </div> -->
            </el-col>
            <el-col :span="11">
              <el-form-item label="项目备注说明：" prop="remark">
                <el-input
                  v-model="ruleForm.remark"
                  style="width: 95%"
                  placeholder="备注清楚项目，以便后期问 题追溯"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item>
                <el-button class="codeCancel" @click="resetForm('ruleForm')"
                  >取消</el-button
                >
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >创建code</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Viewheader from '../../components/viewheader/viewheader.vue'
import codeNavbar from '@/components/viewheader/codeNavbar.vue';
import Footering from '../../components/foot/viewfooter.vue';
import leftNav from '../../views/code/leftNav.vue';
import '../../assets/css/code.css';
import { bizUnitList, bizCreate, bizPage } from '@/api/aidata.js';
export default {
  components: {
    // Viewheader,
    codeNavbar,
    Footering,
    leftNav,
  },
  data() {
    var checkNum1 = (rule, value, callback) => {
      console.log(6666, value, this.checked);
      if (value === '') {
        callback(new Error('请输入每日峰值调用量'));
      } else {
        if (value > 21474836471) {
          callback(new Error('输入上限为21474836471'));
        } else {
          callback();
        }
      }
    };
    var checkNum2 = (rule, value, callback) => {
      console.log(6666, value, this.checked);
      if (value > 21474836471) {
        callback(new Error('输入上限为21474836471'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        // 业务方
        bizUnit: '',
        // 业务名
        bizName: '',
        // 需求人
        bizDemander: '',
        // 有效期状态
        validityStatus: '',

        date: [],

        // 开始时间
        validityStart: '',
        // 结束时间
        validityEnd: '',

        // 日调用上线
        dayCallMax: '',
        // qps上限
        qpsMax: '',
        // 最大调用上限
        callMax: '',
        // 工程接口
        projectInterface: '',
        // 备注
        remark: '',
      },
      checked: false,

      rules: {
        bizUnit: [{ required: true, message: '请选择业务方' }],
        bizName: [{ required: true, message: '请输入项目名' }],
        bizDemander: [{ required: true, message: '请输入需求人' }],
        date: [{ required: true, message: '请选择调用有效时间' }],
        //   date: [
        //     { required: true, validator: checkDate, trigger: 'blur' }
        //   ],
        dayCallMax: [{ required: true, validator: checkNum1 }],
        qpsMax: [{ validator: checkNum2 }],
        projectInterface: [{ required: true, message: '请输入工程接口' }],
      },
      title: '关于我们',
      contents: [
        'AIShipGo是一个专注于跨境电商人工智能技术与解决方案的产品技术平台。凭借着海量的电商数据、国际领先的算法和工程能力，以“让跨境电商的生意更简单、更高效”为目标，致力于为跨境卖家提供全面的人工智能技术支持，解决社媒引流、站内营销、风控安全、直播带货等业务问题。',
        '团队由一群经验丰富的AI专家和跨境电商从业者组成，深入了解跨境电商行业的挑战和机遇，结合先进的NLP、CV、多模态、ML等AIGC技术，为卖家提供全方位的解决方案。产品均已在真实跨境业务中应用，后续将持续开放更多能力。提供网页、SDK、私有化部署等对接方式，满足不同的应用需求，请关注我们，了解更多的最新动态。',
      ],
      // 业务方列表
      bizUnitListVal: '',
      timeChangeClicking: false,
      longTtermClicking: false,
      validityDate: '',
      projectInterfaceLikeResult: '',
      projectInterfaceLikeTre: false,
    };
  },
  mounted() {
    // 获取业务方列表
    this.bizUnitListClick();
  },

  watch: {},
  methods: {
    // 业务方列表
    bizUnitListClick() {
      bizUnitList({}).then(({ data }) => {
        if (data.state === '0x0000') {
          this.bizUnitListVal = data.data;
        } else if (data.state === '0x0008') {
          this.$message({
            message: data.message,
            type: 'error',
          });
          this.$refs.navheader.logOn();
        } else {
          this.$message({
            message: data.message,
            type: 'error',
          });
        }
      });
    },
    // 获取业务方的值
    provinceTypes(value) {
      this.bizUnitListVal.forEach((item) => {
        if (item.bizUnit === value) {
          this.ruleForm.bizUnit = item.bizUnit;
        }
      });
    },
    // 获取时间
    timeChange(val) {
      if (val) {
        // 时间选中
        this.ruleForm.validityStatus = 2;
        this.ruleForm.validityStart = val[0];
        this.ruleForm.validityEnd = val[1];
        this.timeChangeClicking = false;
        this.longTtermClicking = true;
      } else {
        this.ruleForm.validityStatus = '';
        this.ruleForm.validityStart = '';
        this.ruleForm.validityEnd = '';
        this.timeChangeClicking = false;
        this.longTtermClicking = false;
      }
    },
    // 是否是长期
    handleCheckedCitiesChange(val) {
      // console.log(val)
      if (val) {
        // 长期选中
        this.timeChangeClicking = true;
        this.longTtermClicking = false;
        (this.ruleForm.validityStatus = 1),
          (this.ruleForm.date = ['1970-01-01', '1970-01-01']);
        // this.rules.date[0].required = false
      } else {
        // 长期取消
        this.ruleForm.validityStatus = '';
        this.timeChangeClicking = false;
        this.longTtermClicking = false;
        // this.rules.date[0].required = true
      }
    },
    handleClear(e) {
      console.log('清除数据');
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // this.$router.push({
          //     path: '/resultCode'
          // });

          // localStorage.getItem("tokenKey")?localStorage.getItem("tokenKey"):''
          var validityStartValue = new Date(this.ruleForm.validityStart);
          var validityStartValueA = validityStartValue.toString();

          var validityEndValue = new Date(this.ruleForm.validityEnd);
          var validityEndValueA = validityEndValue.toString();

          bizCreate({
            bizUnit: this.ruleForm.bizUnit,
            bizName: this.ruleForm.bizName,
            bizDemander: this.ruleForm.bizDemander,
            validityStatus: parseInt(this.ruleForm.validityStatus),
            validityStart: this.ruleForm.validityStart
              ? validityStartValueA
              : undefined,
            validityEnd: this.ruleForm.validityEnd
              ? validityEndValueA
              : undefined,
            dayCallMax: this.ruleForm.dayCallMax
              ? parseInt(this.ruleForm.dayCallMax)
              : undefined,
            qpsMax: this.ruleForm.qpsMax
              ? parseInt(this.ruleForm.qpsMax)
              : undefined,
            callMax: this.ruleForm.callMax
              ? parseInt(this.ruleForm.callMax)
              : undefined,
            projectInterface: this.ruleForm.projectInterface,
            remark: this.ruleForm.remark,
          }).then((res) => {
            // console.log(res)
            if (res.data.state === '0x0000') {
              // console.log(res.data.data)

              if (res.data.data.validityStatus == 1) {
                this.validityDate = '长期';
              } else {
                this.validityDate =
                  this.ruleForm.validityStart +
                  ' 至 ' +
                  this.ruleForm.validityEnd;
              }
              this.$router.push({
                path: '/resultCode',
                query: {
                  bizName: res.data.data.bizName,
                  bizCode: res.data.data.bizCode,
                  validityDateValue: this.validityDate,
                  projectInterface: res.data.data.projectInterface,
                },
              });
            } else if (res.data.state === '0x0008') {
              this.$message({
                message: res.data.message,
                type: 'error',
              });
              this.$refs.navheader.logOn();
            } else {
              this.$message({
                message: res.data.message,
                type: 'error',
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 模糊搜索
    // bizPageOn(val){
    //     console.log(val)
    //     bizPage({
    //         projectInterfaceLike:val
    //     }).then((res) => {
    //         if (res.data.state === "0x0000") {
    //             console.log(res.data.data.page.result.length)
    //             if(res.data.data.page.result.length==0){
    //                 this.projectInterfaceLikeTre = false
    //             }else{
    //                 this.projectInterfaceLikeTre = true
    //                 this.projectInterfaceLikeResult = res.data.data.page.result
    //             }
    //         } else if (res.data.state === "404") {
    //             this.$message({
    //                 message: res.data.message,
    //                 type: "error",
    //                 offset: window.screen.height / 2
    //             });
    //             this.$refs.navheader.logOn()
    //         } else {
    //             this.$message({
    //                 message: res.data.message,
    //                 type: "error",
    //                 offset: window.screen.height / 2
    //             });
    //         }
    //     });
    // },
    // 点击选中
    // selectprojectInterfaceValue(val){
    //     this.ruleForm.projectInterface = val
    //     this.projectInterfaceLikeTre = false
    // },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      // 长期按钮、禁用等重置
      this.checked = false;
      this.timeChangeClicking = false;
      this.longTtermClicking = false;
    },
  },
};
</script>
